<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0"
    >
      <v-row>
        <v-col
          cols="2"
          class="py-0"
        >
          <v-card
            class="mx-auto"
            max-width="500"
            flat
          >
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="gotoRoute(item.link)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <v-col>
          <router-view />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Home',

  data() {
    return {
      items: [
        {
          icon: 'mdi-translate',
          text: 'Languages',
          link: 'dashboard/languages',
        },
        {
          icon: 'mdi-shape',
          text: 'Categories',
          link: 'dashboard/categories',
        },
        {
          icon: 'mdi-video',
          text: 'Capsules',
          link: 'dashboard/capsules',
        },
        {
          icon: 'mdi-video',
          text: 'Ad Videos',
          link: 'dashboard/ad-videos',
        }, {
          icon: 'mdi-video',
          text: 'Ad Images',
          link: 'dashboard/ad-images',
        },
        {
          icon: 'mdi-account-multiple',
          text: 'Users',
          link: 'dashboard/users',
        },
      ],
      model: 1,
    };
  },

  methods: {
    gotoRoute(link) {
      this.$router.push(`/${link}`);
    },
  },
};
</script>
